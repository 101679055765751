import React from "react"
import styled from "styled-components"
import colors from "../../../constants/colors"
import breakpoints from "../../../constants/breakpoints"

const Dash = styled.div<{ color: string }>`
  width: 18px;
  height: 1px;
  background-color: ${({ color }) => color};

  @media ${breakpoints.laptop} {
    width: 24px;
    margin-right: 1px;
    transform-origin: right;
    transition: transform 350ms cubic-bezier(0.24, 0, 0.18, 1.5) 100ms,
      background-color 200ms cubic-bezier(0.24, 0, 0.18, 1.5) 100ms;
  }
`

const TopLine = styled.div<{ color: string }>`
  @media ${breakpoints.mobileOnly} {
    display: none;
  }

  @media ${breakpoints.laptop} {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 7px;
    height: 1px;
    background-color: ${({ color }) => color};
    transform-origin: right;
    transform: translateX(0) translateZ(0) rotate(45deg) scaleX(0);
    transition: transform 200ms cubic-bezier(0.5, 0.15, 0, 1),
      background-color 200ms cubic-bezier(0.5, 0.15, 0, 1);
  }
`

const BottomLine = styled.div<{ color: string }>`
  @media ${breakpoints.mobileOnly} {
    display: none;
  }

  @media ${breakpoints.laptop} {
    position: absolute;
    right: 0;
    top: 0;
    width: 7px;
    height: 1px;
    background-color: ${({ color }) => color};
    transform-origin: right;
    transform: translateX(0) translateZ(0) rotate(-45deg) scaleX(0);
    transition: transform 200ms cubic-bezier(0.5, 0.15, 0, 1),
      background-color 200ms cubic-bezier(0.5, 0.15, 0, 1);
  }
`

const getHoverProps = (hoverColor?: string) => `
  ${Dash} {
    transform: scaleX(0.75) translateZ(0);
    transition: transform 100ms cubic-bezier(0.5, 0.15, 0, 1);
  }

  ${TopLine} {
    transform: translateX(1px) translateZ(0) rotate(45deg) scaleX(1);
    transition: transform 400ms cubic-bezier(0, 1.4, 0.5, 1) 60ms;
  }

  ${BottomLine} {
    transform: translateX(1px) translateZ(0) rotate(-45deg) scaleX(1);
    transition: transform 400ms cubic-bezier(0, 1.4, 0.5, 1) 90ms;
  }

  ${
    hoverColor &&
    `
    ${Dash} {
      background-color: ${hoverColor};
      transition: transform 100ms cubic-bezier(0.5, 0.15, 0, 1), background-color 150ms cubic-bezier(0.5, 0.15, 0, 1);
    }

    ${TopLine} {
      background-color: ${hoverColor};
      transition: transform 400ms cubic-bezier(0, 1.4, 0.5, 1) 60ms, background-color 150ms cubic-bezier(0, 1.4, 0.5, 1) 60ms;
    }

    ${BottomLine} {
      background-color: ${hoverColor};
      transition: transform 400ms cubic-bezier(0, 1.4, 0.5, 1) 90ms, background-color 150ms cubic-bezier(0, 1.4, 0.5, 1) 90ms;
    }
  `
  }
`

const activeProps = `
  ${Dash} {
    transform: scaleX(0.6) translateZ(0);
    transition: none;
  }
`

export const Container = styled.div<AnimatedDashProps>`
  position: relative;
  width: 24px;
`

export const animationStyles = `
  &:active {
    ${activeProps}
  }

  @media ${breakpoints.laptop} {
    &:hover {
      ${getHoverProps()}
    }
  }
`

export const getAnimationStyles = (hoverColor) => `
  &:active {
    ${activeProps}
  }

  @media ${breakpoints.laptop} {
    &:hover {
      ${getHoverProps(hoverColor)}
    }
  }
`

const Animation = styled.div<AnimatedDashProps>`
  position: absolute;
`

interface AnimatedDashProps {
  color?: string
}

const AnimatedDash: React.FC<AnimatedDashProps> = ({
  color = colors.black,
}) => {
  return (
    <Container>
      <Animation>
        <Dash color={color} />
        <TopLine color={color} />
        <BottomLine color={color} />
      </Animation>
    </Container>
  )
}

export default AnimatedDash
