import React from "react"
import styled from "styled-components"
import colors from "../../constants/colors"
import animation, { cubicBezier } from "../../constants/animation"
import breakpoints from "../../constants/breakpoints"
import { smallcapsRegular14Styles } from "../../constants/typography"
import FilterTag, { Container as FilterContainer, Text } from "../FilterTag"
import { DesktopOnly } from "../../utils/MediaQuery"

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 44px 20px 40px;
  flex-wrap: wrap;
  align-items: center;
  z-index: 1;

  @media ${breakpoints.tablet} {
    padding: 44px 32px 40px;
  }

  @media ${breakpoints.laptop} {
    padding: 50px calc(2 / 12 * 100vw) 73px;
  }
`

const Label = styled.div`
  ${smallcapsRegular14Styles}
  margin: 0 24px 8px 0;
  letter-spacing: 0.9px;
  line-height: 24px;

  @media ${breakpoints.laptop} {
    margin-bottom: 3px;
  }
`

const SuggestionWrapper = styled.div`
  margin: 0 8px 8px 0;
  flex-shrink: 0;

  ${FilterContainer} {
    height: 40px;
    align-items: center;
    justify-content: center;
  }

  ${Text} {
    color: ${colors.black};
  }

  @media ${breakpoints.laptop} {
    ${FilterContainer} {
      height: 32px;
      transition: background ${animation.medium} ${cubicBezier};
      background: ${colors.dividerLineGray};
      border: none;

      &:hover {
        background: ${colors.lightGray};
        transition: background ${animation.quick} ${cubicBezier};
      }
    }

    ${Text} {
      ${smallcapsRegular14Styles}
      padding: 4px 0 0;
      color: ${colors.mediumDarkGray};
      line-height: 14px;
      border: none;
    }
  }
`

export interface SuggestionProps {
  name?: string
  productName?: string
}

export interface SuggestionsProps {
  onClickSuggestion?: (suggestion: string) => void
  searchSuggestions: Array<SuggestionProps>
  tags?: { id: string; value: string }[]
}

const Suggestions: React.FC<SuggestionsProps> = ({
  tags,
  onClickSuggestion,
  searchSuggestions,
}: SuggestionsProps) => {
  const getClickHandler = (suggestion) => () => {
    if (onClickSuggestion) {
      onClickSuggestion(suggestion)
    }
  }
  return (
    <Container>
      <DesktopOnly>
        <Label>Popular</Label>
      </DesktopOnly>
      {tags?.map(({ id, value }, i) => (
        <SuggestionWrapper key={`suggestion-${i}-${id}`}>
          <FilterTag onClick={getClickHandler(value)} text={value || ""} />
        </SuggestionWrapper>
      ))}
    </Container>
  )
}

export default Suggestions
