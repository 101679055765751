import React from "react"
import styled from "styled-components"
import MediaQuery from "react-responsive"
import breakpoints from "@constants/breakpoints"

const VIDEO_NODE_NAME = "VIDEO"

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 70%;
  margin: auto;
  z-index: 1;
`

const StyledVideo = styled.video`
  object-fit: cover;
`

interface IProps extends React.PropsWithoutRef<JSX.IntrinsicElements["video"]> {
  disableClickToPause?: boolean
  mobileSrc?: string
  src?: string
}

const onClick = (event) => {
  const videoElement = event.target.previousSibling
  if (videoElement && videoElement.nodeName === VIDEO_NODE_NAME) {
    if (videoElement.paused) {
      const playbackStarted = videoElement.play()
      if (playbackStarted !== undefined) {
        playbackStarted.catch((error) => {
          // autoPlay not allowed or not supported
        })
      }
    } else {
      videoElement.pause()
    }
  }
}

const Video = React.forwardRef<HTMLVideoElement, IProps>(
  ({ disableClickToPause, mobileSrc, src, ...rest }: IProps, ref) => {
    const [isMounted, setIsMounted] = React.useState(false)
    React.useEffect(() => {
      setTimeout(() => {
        setIsMounted(true)
      }, 0)
    }, [])
    if (!isMounted) return null
    return (
      <MediaQuery query={breakpoints.mobileAndTabletOnly}>
        {(isMobile) => {
          const videoUrl = isMobile ? mobileSrc || src : src
          return (
            <VideoWrapper>
              <StyledVideo ref={ref} width="100%" height="100%" {...rest}>
                <source src={videoUrl} />
              </StyledVideo>
              {!rest.autoPlay && isMobile && !disableClickToPause && (
                <VideoOverlay
                  onClick={disableClickToPause ? undefined : onClick}
                />
              )}
            </VideoWrapper>
          )
        }}
      </MediaQuery>
    )
  }
)

export default Video
