import React from "react"
import styled, { css } from "styled-components"
import colors from "../../constants/colors"
import animation, { cubicBezier } from "../../constants/animation"
import breakpoints from "../../constants/breakpoints"
import { Cart, Menu as MenuIcon } from "../icons"
import Logo from "../Logo"
import DefaultHeaderContent from "./DefaultHeaderContent"
import useScrollHandler from "../../hooks/useScrollHandler"
import Menu from "@components/Menu"

const Background = styled.div<{
  shorten?: boolean
}>`
  ${({ shorten }) => css`
    z-index: 999;
    position: fixed;
    top: 0;
    will-change: transform;
    transform-origin: top;
    transform: ${shorten
      ? "scaleY(calc(64 / 88)) translateZ(0)"
      : "scaleY(1) translateZ(0)"};
    transition: transform ${animation.quick} ${cubicBezier};
    width: 100%;
    height: 88px;
    background-color: ${colors.offWhite};
  `}
`

const paddingRight = "0.5 / 12 * 100vw - 38px"
const Container = styled.div<{
  shorten?: boolean
}>`
  ${({ shorten }) => css`
    z-index: 1000;
    position: fixed;
    top: 0;
    flex-direction: row;
    align-items: center;
    display: flex;
    transform-origin: top;
    transition: height ${animation.quick} ${cubicBezier};
    will-change: height;
    width: 100%;
    height: ${shorten ? "64px" : "88px"};
    padding: 0 calc(${paddingRight}) 0
      calc(${paddingRight} + (0.25 / 12 * 100vw));
    border-bottom: solid 1px ${colors.dividerLineGray};
  `}
`

const Content = styled.div`
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  overflow: hidden;
  height: 100%;
  margin-left: calc(0.25 / 12 * 100vw);
`

const defaultHeaderProps = `
  display: flex;
  height: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`

const Header = styled.div`
  ${defaultHeaderProps}
`

const Headers = styled.div`
  height: 100%;
  min-width: 0;
  flex-grow: 1;
`

const hideAnimatedHeader = `
  opacity: 0;
  transition: opacity ${animation.quick} ${cubicBezier};
  pointer-events: none;
`
const AnimatedHeader = styled.div<{ hide?: boolean }>`
  ${defaultHeaderProps}
  opacity: 1;
  will-change: opacity;
  transition: opacity ${animation.medium} ${cubicBezier};
  ${({ hide }) => (hide ? hideAnimatedHeader : "")}
`

const showFixedHeaderProps = `
  opacity: 1;
  transform: translateY(calc(-50% - 32px)) translateZ(0);
  transition: opacity ${animation.medium} ${cubicBezier},
    transform ${animation.medium} ${cubicBezier};
  z-index: 1;
`
const FixedHeader = styled.div<{ show?: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  will-change: opacity, transform;
  opacity: 0;
  transform: translateY(-50%) translateZ(0);
  transition: opacity ${animation.medium} ${cubicBezier},
    transform ${animation.quick} ${cubicBezier};
  pointer-events: none;
  z-index: -1;
  ${({ show }) => (show ? showFixedHeaderProps : "")}
`

const Button = styled.button`
  display: flex;
  height: 100%;
  padding: 0 calc(0.25 / 12 * 100vw);
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  will-change: background-color;
  transition: background-color ${animation.medium} ${cubicBezier};
  background: none;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  @media ${breakpoints.largeDesktop} {
    width: 88px;
    padding: 0;

    &:hover {
      background-color: ${colors.dividerLineGray};
    }
  }
`

const CartButton = styled(Button)`
  @media ${breakpoints.largeDesktop} {
    margin-left: 8px;
  }
`

const MenuButton = styled(Button)``

const Icon = styled.div`
  width: 24px;
  height: 24px;
`

export interface DesktopHeaderProps {
  supportLink: string
  onClickSearch?: () => void
  onClickMenu?: () => void
  megaMenuHeaderRef?: any
  megaMenuHeaderItems?: Array<{
    label: string
    onClick?: () => void
  }>
  megaMenuActiveItem?: string
  expanded?: boolean
  showFixedHeader?: boolean
  fixedHeaderTopRef?: any
  FixedHeaderComponent?: React.ReactNode
  shortenDesktopHeader?: boolean
}

const DesktopHeader = (props: DesktopHeaderProps) => {
  const { showFixedHeader, fixedHeaderTopRef, FixedHeaderComponent } = props

  const [refPos, setRefPos] = React.useState<any>()
  const handleShowFixedHeader = () => {
    if (fixedHeaderTopRef && fixedHeaderTopRef.current) {
      setRefPos(fixedHeaderTopRef.current.getBoundingClientRect().top)
    }
  }

  if (fixedHeaderTopRef) {
    useScrollHandler({ onScroll: handleShowFixedHeader })
  }

  const shouldShowFixedHeader =
    fixedHeaderTopRef && refPos ? refPos < -60 : !!showFixedHeader

  const [expanded, setExpanded] = React.useState(false)

  return (
    <>
      <Background shorten={props.shortenDesktopHeader} />
      <Container id="desktopHeader" shorten={props.shortenDesktopHeader}>
        <Logo />
        <Content>
          {showFixedHeader ? (
            <Headers>
              <AnimatedHeader hide={shouldShowFixedHeader}>
                <DefaultHeaderContent {...props} />
              </AnimatedHeader>
              <FixedHeader show={shouldShowFixedHeader}>
                {FixedHeaderComponent}
              </FixedHeader>
            </Headers>
          ) : (
            <Header>
              <DefaultHeaderContent {...props} />
            </Header>
          )}
          <MenuButton onClick={() => setExpanded(!expanded)}>
            <Icon>
              <MenuIcon />
            </Icon>
          </MenuButton>
        </Content>
      </Container>
      <Menu isOpen={expanded} onRequestClose={() => setExpanded(false)} />
    </>
  )
}

export default DesktopHeader
