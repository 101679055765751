import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import colors from "../../constants/colors"
import animation, { cubicBezier } from "../../constants/animation"
import breakpoints from "../../constants/breakpoints"
import { smallcapsBold13Styles } from "../../constants/typography"
import { Search, Cart, Menu, Chevron } from "../icons"
import Logo from "@components/Logo"
import useScrollHandler from "@hooks/useScrollHandler"
import { getIn } from "../../utils"
// import { getShopURL } from "@utils/urls"
import * as R from "ramda"

const Container = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: ${colors.offWhite};
  z-index: 1000;
`

const HeaderWrapper = styled.div`
  display: flex;
  min-height: 56px;
  padding: 0 0 0 20px;
  align-items: center;
  border-bottom: solid 1px ${colors.dividerLineGray};

  @media ${breakpoints.tablet} {
    min-height: 72px;
    padding: 0 12px 0 32px;
  }
`

const showFixedHeaderProps = `
  opacity: 1;
  transform: translateY(0) translateZ(0);
  transition: opacity ${animation.medium} ${cubicBezier},
    transform ${animation.medium} ${cubicBezier};
  z-index: 1;
`
const FixedHeader = styled.div<{ show?: boolean }>`
  display: flex;
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  width: 100%;
  height: 49px;
  align-items: center;
  justify-content: space-between;
  will-change: opacity, transform;
  opacity: 0;
  transform: translateY(-50%) translateZ(0);
  transition: opacity ${animation.medium} ${cubicBezier},
    transform ${animation.quick} ${cubicBezier};
  background-color: ${colors.offWhite};
  border-top: solid 1px ${colors.white};
  border-bottom: solid 1px ${colors.dividerLineGray};
  pointer-events: none;
  z-index: -1;

  @media ${breakpoints.tablet} {
    top: 72px;
  }

  ${({ show }) => (show ? showFixedHeaderProps : "")}
`

const Content = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
`

const Button = styled.button`
  display: flex;
  width: 56px;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

const SearchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

const CartButton = styled(Button)`
  position: relative;
  margin-left: 8px;

  @media ${breakpoints.tablet} {
    margin-left: 35px;
  }
`

const MenuButton = styled(Button)`
  margin-left: 12px;

  @media ${breakpoints.tablet} {
    margin-left: 42px;
  }
`

const Icon = styled.div`
  width: 24px;
  height: 24px;
`

const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  transform: translateX(-10px);

  ${Icon} {
    flex-shrink: 0;
    transform: rotate(180deg);
  }
`

const BackButtonText = styled.div`
  ${smallcapsBold13Styles}
  width: calc(100vw - 250px);
  padding: 3px 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media ${breakpoints.tablet} {
    width: calc(100vw - 300px);
  }
`

interface MobileHeaderProps {
  supportLink: string
  onClickSearch?: () => void
  onClickMenu?: () => void
  expanded?: boolean
  showFixedHeader?: boolean
  fixedHeaderTopRef?: any
  FixedHeaderComponent?: React.ReactNode
}

const MobileHeader = (props: MobileHeaderProps) => {
  const shopURL = "https://shop.urbanarmorgear.com"
  // brand &&
  // deviceType &&
  // getShopURLForBrandDeviceType(brand.slug, deviceType.slug)

  const { showFixedHeader, fixedHeaderTopRef, FixedHeaderComponent } = props

  const [refPos, setRefPos] = React.useState<any>()
  const handleShowFixedHeader = () => {
    if (fixedHeaderTopRef && fixedHeaderTopRef.current) {
      setRefPos(fixedHeaderTopRef.current.getBoundingClientRect().top)
    }
  }

  if (showFixedHeader && fixedHeaderTopRef) {
    useScrollHandler({ onScroll: handleShowFixedHeader })
  }

  const shouldShowFixedHeader =
    showFixedHeader && fixedHeaderTopRef && refPos
      ? refPos < -60
      : !!showFixedHeader

  return (
    <Container>
      <HeaderWrapper>
        <Logo />
        <Content>
          <SearchButton onClick={props.onClickSearch}>
            <Icon>
              <Search />
            </Icon>
          </SearchButton>
          <MenuButton onClick={props.onClickMenu}>
            <Icon>
              <Menu />
            </Icon>
          </MenuButton>
        </Content>
        {showFixedHeader && (
          <FixedHeader show={shouldShowFixedHeader}>
            {FixedHeaderComponent}
          </FixedHeader>
        )}
      </HeaderWrapper>
    </Container>
  )
}

export default MobileHeader
