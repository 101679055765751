import React from "react"
import styled from "styled-components"
//
import colors from "@constants/colors"
import breakpoints from "@constants/breakpoints"
import { smallcapsBold13Styles } from "@constants/typography"

const LabelContainer = styled.div<{ color?: string }>`
  ${smallcapsBold13Styles}
  display: flex;
  height: 24px;
  padding: 3px 8px 0;
  align-items: center;
  color: ${colors.offWhite};
  background-color: ${({ color }) => (color ? color : colors.brandOrange)};
  border-radius: 2px;

  @media ${breakpoints.tablet} {
    position: absolute;
    left: 0;
    bottom: 26px;
    padding: 3px 8px 0 24px
    background-color: ${colors.black};
  }
`
export function Label({ text, color }: { text: string; color?: string }) {
  return <LabelContainer color={color}>{text}</LabelContainer>
}
