import React from "react"
import styled from "styled-components"
import Masonry from "react-masonry-css"
import { GridImage } from "./blocks/image"
import { GridVideo } from "./blocks/video"
import { GridPDF } from "./blocks/pdf"
import { body16MainStyles } from "@constants/typography"

const Container = styled.div`
  width: 100%;
`

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const NoImagesText = styled.p`
  ${body16MainStyles}
  text-align:center;
`
interface ExploreImageGridProps {
  gallery: any[]
  onRequestCloseSearch?: () => void
}

const breakpointColumnsObj = {
  default: 3,
  968: 2,
  600: 1,
}

const ExploreImageGrid: React.FC<ExploreImageGridProps> = ({
  gallery,
  onRequestCloseSearch,
}) => {
  return (
    <Container>
      <Content>
        {(!gallery || (Array.isArray(gallery) && gallery.length === 0)) && (
          <NoImagesText>No Gallery Images</NoImagesText>
        )}
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid w-full"
          columnClassName="my-masonry-grid_column"
        >
          {gallery?.map((node, index) => {
            switch (node.__typename) {
              case "ContentfulGalleryImage": {
                return (
                  <GridImage
                    key={node.slug ? `${node.slug}-${node.id}` : node.id}
                    file={node.asset.file}
                    index={index}
                    image={{
                      ...node.asset.fluid,
                      __typename: "ContentfulAsset",
                    }}
                    onRequestCloseSearch={onRequestCloseSearch}
                  />
                )
              }
              case "ContentfulGalleryVideo": {
                return (
                  <GridVideo
                    key={node.slug ? `${node.slug}-${node.id}` : node.id}
                    index={index}
                    title={node.title}
                    description={node.description}
                    playVideoLink={node.playVideoLink}
                    lowResDownloadLink={node.lowResDownloadLink}
                    highResDownloadLink={node.highResDownloadLink}
                    image={{
                      ...node.thumbnail.fluid,
                      __typename: "ContentfulAsset",
                    }}
                    onRequestCloseSearch={onRequestCloseSearch}
                  />
                )
              }

              case "ContentfulGalleryPdf": {
                return (
                  <GridPDF
                    key={node.slug ? `${node.slug}-${node.id}` : node.id}
                    index={index}
                    file={node.pdf.file}
                    image={{
                      ...node.displayImage.fluid,
                      __typename: "ContentfulAsset",
                    }}
                    onRequestCloseSearch={onRequestCloseSearch}
                  />
                )
              }
              default:
                return null
            }
          })}
        </Masonry>
      </Content>
    </Container>
  )
}

export default ExploreImageGrid
