export default {
  black: "#000000",
  black03: "rgba(0, 0, 0, 0.03)",
  black10: "rgba(0, 0, 0, 0.1)",
  black50: "rgba(0, 0, 0, 0.5)",
  hoverBlack: "#111111",
  reflowBlack: "#131314",
  reflowBlackHover: "#1A1A1B",
  offBlack: "#292A2D",
  white: "#FFFFFF",
  offWhite: "#FFFEFD",
  anotherOffWhite: "#FAFAFA",
  lightGray: "#EBEAE9",
  mediumGray: "#A7ABAA",
  mediumGray10Percent: "rgba(167, 171, 170, 0.1)",
  mediumDarkGray: "#75787B",
  sidebarGray: "#FAF9F8",
  sideBarHeaderGray: "#EDECEB",
  promoBannerDesktopGraySeriously: "#FCFBFA",
  dividerLineGray: "#F3F2F1",
  trashBarGray: "#ECEBEA",
  backgroundGray: "#F5F4F3",
  backgroundGray20: "rgba(245, 244, 243, 0.2)",
  backgroundGray30: "rgba(245, 244, 243, 0.3)",
  backgroundGray40: "rgba(245, 244, 243, 0.4)",
  backgroundGray70: "rgba(245, 244, 243, 0.7)",
  backgroundGrayHover: "#E9E8E7",
  borderGray: "#C4C4C4",
  dashGray: "#E5E4E4",
  disabledGray: "#E3E3E3",
  copyrightGray: "#3F4145",
  copyrightGrayHover: "#727478",
  justAnotherGray: "#E8E8E8",
  brandOrange: "#F26722",
  brandMagenta: "#9C3769",
  brandHover: "#FF5E5E",
  brandHoverOutline: "#FFE1E1",
  errorBackground: "#FF7171",
  errorBackground05: "rgba(255, 113, 113, 0.05)",
  errorBackground10: "rgba(255, 113, 113, 0.10)",
}
