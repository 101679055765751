import React, { useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import colors from "@constants/colors"
import animation, { cubicBezier } from "@constants/animation"
import breakpoints from "@constants/breakpoints"
import { h3Styles, smallcapsRegular13Styles } from "@constants/typography"
import { Close } from "../icons"
import { DesktopOnly } from "@utils/MediaQuery"
import * as config from "../../config"

const Container = styled.div<{ isOpen?: boolean }>`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 calc(1 / 12 * 100vw);
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
  will-change: opacity, transform;
  transition: opacity 200ms ${cubicBezier};
  background: ${colors.black};
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  overflow-y: auto;
  z-index: 1001;
  cursor: default;
  pointer-events: ${(props) => (props.isOpen ? "" : "none")};
`

const Content = styled.div<{ isOpen?: boolean }>`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
  will-change: opacity;
  transition: opacity 300ms ${cubicBezier} 50ms;
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`

const linkStyles = `
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

const InternalLink = styled(Link)`
  ${linkStyles}
`

const ExternalLink = styled.a`
  ${linkStyles}
`

const Text = styled.div`
  ${h3Styles}
  padding: 32px 0;
  will-change: transform;
  transform: translateX(64px);
  transition: transform ${animation.medium} ${cubicBezier};
  background: ${colors.black};
  color: ${colors.offWhite};

  @media ${breakpoints.laptop} {
    ${InternalLink}:hover & {
      transform: translateX(0);
    }
    ${ExternalLink}:hover & {
      transform: translateX(0);
    }
  }
`

const Dash = styled.div`
  width: 30px;
  height: 1px;
  margin-left: 34px;
  will-change: transform;
  transform: translateX(64px);
  transition: opacity ${animation.medium} ${cubicBezier} 50ms,
    transform ${animation.medium} ${cubicBezier} 50ms;
  background: ${colors.offWhite};
  opacity: 0;

  @media ${breakpoints.laptop} {
    ${InternalLink}:hover & {
      transform: translateX(0);
      opacity: 1;
    }
    ${ExternalLink}:hover & {
      transform: translateX(0);
      opacity: 1;
    }
  }
`

const Button = styled.button`
  display: flex;
  width: 56px;
  height: 56px;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${colors.dividerLineGray};
  border: none;
  border-radius: 50%;
  cursor: pointer;

  :focus {
    outline: none;
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
`

const FooterLink = styled(Link)`
  ${smallcapsRegular13Styles}
  margin-left: 60px;
  will-change: opacity;
  transition: opacity ${animation.quick} ${cubicBezier};
  opacity: 0.2;

  @media ${breakpoints.laptop} {
    &:hover {
      opacity: 0.3;
    }
  }
`

interface DesktopMenuProps {
  isOpen?: boolean
  globalData: any
  onRequestClose?: () => void
}

const DesktopMenu = (props: DesktopMenuProps) => {
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27 && props.onRequestClose) {
        props.onRequestClose()
      }
    }
    window.addEventListener("keydown", handleEsc)

    return () => {
      window.removeEventListener("keydown", handleEsc)
    }
  }, [])

  return (
    <DesktopOnly>
      <Container isOpen={props.isOpen}>
        <Content isOpen={props.isOpen}>
          <Button onClick={props.onRequestClose}>
            <Close />
          </Button>
          <Menu>
            <InternalLink to="/" onClick={props.onRequestClose}>
              <Text>UAG NEWS</Text>
              <Dash />
            </InternalLink>
            <ExternalLink
              // TODO: update href
              href={config.SHOP_UAG_URL}
              onClick={props.onRequestClose}
            >
              <Text>Shop UAG</Text>
              <Dash />
            </ExternalLink>
            {/* href={getIn(props, "globalData.supportLink", "")} */}
            <ExternalLink
              href={config.SHOP_UAG_U_URL}
              onClick={props.onRequestClose}
            >
              <Text>Shop [U]</Text>
              <Dash />
            </ExternalLink>
          </Menu>
          <Footer>
            <FooterLink to="/distributors" onClick={props.onRequestClose}>
              Distributors
            </FooterLink>
            <FooterLink to="/dealscout" onClick={props.onRequestClose}>
              Dealer Programs
            </FooterLink>
          </Footer>
        </Content>
      </Container>
    </DesktopOnly>
  )
}

export default DesktopMenu
