import React from "react"
import styled from "styled-components"
import colors from "@constants/colors"
import animation, { cubicBezier } from "@constants/animation"
import breakpoints from "@constants/breakpoints"
import {
  h4Styles,
  body16MainStyles,
  smallcapsBold13Styles,
  smallcapsRegular14Styles,
} from "@constants/typography"
import { Close as CloseIcon, Search as SearchIcon } from "@components/icons"
import Backdrop from "../Backdrop"
import SearchBlock, { SearchBlockProps } from "../SearchBlock"
import Suggestions, { SuggestionProps } from "./Suggestions"
import useModalBackgroundClose from "@hooks/useModalBackgroundClose"
import { uniqueId } from "lodash"
import * as R from "ramda"
import ExploreImageGrid from "@components/ExploreImageGrid"
import { useClickAway } from "@hooks/useClickAway"

const openProps = `
  opacity: 1;
  transform: translateY(0);
  transition: opacity ${animation.quick} ${cubicBezier},
    transform ${animation.quick} ${cubicBezier};
`
const Container = styled.div<any>`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  flex-direction: column;
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity ${animation.medium} ${cubicBezier},
    transform ${animation.quick} ${cubicBezier};
  will-change: opacity, transform;
  background-color: ${colors.offWhite};
  overflow: ${({ showPlaceholder }) =>
    showPlaceholder ? "visible" : "hidden"};
  cursor: default;
  z-index: 1001;
  ${({ isOpen }) => (isOpen ? openProps : "")}

  @media ${breakpoints.laptop} {
    padding-top: 53px;
  }
`

const searchWrapperStyles = `
  display: flex;
  padding-left: 20px;
  align-items: center;
  border-bottom: solid 1px ${colors.dividerLineGray};
  z-index: 1;

  @media ${breakpoints.tablet} {
    padding-left: 32px;
  }

  @media ${breakpoints.laptop} {
    height: 52px;
    padding-left: calc(2 / 12 * 100vw);
    border: none;
  }
`

const SearchWrapperMobileAndTablet = styled.div`
  ${searchWrapperStyles}

  @media ${breakpoints.laptop} {
    display: none;
  }
`

const SearchWrapperDesktop = styled.div`
  ${searchWrapperStyles}
  display: none;

  @media ${breakpoints.laptop} {
    display: flex;
  }
`

const SearchIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  flex-grow: 0;
  flex-shrink: 0;
`

const InputWrapper = styled.div`
  flex-grow: 1;
  flex-shrink: 1;

  @media ${breakpoints.laptop} {
    width: calc((8 / 12 * 100vw) + 24px);
    padding-right: calc(1 / 12 * 100vw);
    flex-grow: 0;
  }
`

// Interstate padding
const placeholderStyles = `
  color ${colors.mediumGray};
  opacity: 1;

  @media ${breakpoints.mobileAndTabletOnly} {
    line-height: normal !important;
  }

  @media ${breakpoints.laptop} {
    opacity: 0.5;
  }
`
const Input = styled.input`
  ${body16MainStyles}
  width: 100%;
  height: 64px;
  padding-left: 13px;
  border: none;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }

  ::-webkit-input-placeholder {
    ${placeholderStyles}
  }
  ::-moz-placeholder {
    ${placeholderStyles}
  }
  :-ms-input-placeholder {
    ${placeholderStyles}
  }
  :-moz-placeholder {
    ${placeholderStyles}
  }

  @media ${breakpoints.laptop} {
    ${h4Styles}
    padding-top: 4px;
    padding-left: 17px;
  }
`

const ClearButton = styled.button`
  display: flex;
  height: 64px;
  width: 56px;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  &:focus {
    outline: none;
  }

  @media ${breakpoints.laptop} {
    &:hover {
      cursor: pointer;
    }
  }
`

const ClearIconWrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${colors.dividerLineGray};
`

const DoneButton = styled.button`
  ${body16MainStyles}
  width: 88px;
  height: 64px;
  flex-grow: 0;
  flex-shrink: 0;
  color: ${colors.mediumGray};
  background-color: transparent;
  border: none;
  border-left: solid 1px ${colors.mediumGray};
  border-left: solid 1px ${colors.dividerLineGray};
  &:focus {
    outline: none;
  }

  @media ${breakpoints.laptop} {
    &:hover {
      cursor: pointer;
    }
  }
`

const CloseButtonWrapper = styled.div`
  width: calc(1 / 12 * 100vw);
  padding-left: 6px;
  align-items: center;
  justify-content: flex-start;
`

const CloseButton = styled.button`
  display: flex;
  width: 56px;
  height: 56px;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
  background-color: ${colors.dividerLineGray};
  border: none;
  border-radius: 28px;

  &:focus {
    outline: none;
  }

  @media ${breakpoints.laptop} {
    &:hover {
      cursor: pointer;
    }
  }
`

const CloseIconWrapper = styled.div`
  width: 40px;
  height: 40px;
`

const placeholderVisibleProps = `
  opacity: 1;
  transition: opacity ${animation.quick} ${cubicBezier};
`
const ResultsPlaceholder = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  opacity: 0;
  transition: opacity ${animation.quick} ${cubicBezier} ${animation.quick};
  background-color: ${colors.offWhite};

  ${({ isVisible }) => (isVisible ? placeholderVisibleProps : "")}
`

const Results = styled.div`
  min-height: calc(100vh - 66px);
  padding: 36px 20px 28px;
  z-index: 1;

  @media ${breakpoints.tablet} {
    padding: 44px 32px 28px;
  }

  @media ${breakpoints.laptop} {
    min-height: calc(100vh - 105px);
    padding: 58px calc(2 / 12 * 100vw) 176px;
  }
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 18px 0;

  @media ${breakpoints.tablet} {
    margin: 22px 0;
  }

  @media ${breakpoints.laptop} {
    margin: 36px 0 40px;
    background-color: ${colors.dividerLineGray};
  }
`

const Title = styled.div`
  ${smallcapsBold13Styles}
  margin-bottom: 20px;
`

const Products = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
`

const ProductWrapper = styled.div`
  width: 47.5%;
  margin-bottom: 30px;
  flex-shrink: 0;

  @media ${breakpoints.tablet} {
    width: 47.5%;
    margin-bottom: 32px;
  }
`

const FilterWrapper = styled.div`
  margin-bottom: 48px;
`

const FilterButton = styled.button<{ active: boolean }>`
  background-color: ${({ active }) =>
    active ? colors.offBlack : colors.dividerLineGray};
  border: none;
  height: 64px;
  padding: 0 48px;
  border-radius: 2px;
  cursor: pointer;
  ${({ active }) => (active ? smallcapsBold13Styles : smallcapsRegular14Styles)}

  color: ${({ active }) => (active ? colors.lightGray : colors.mediumDarkGray)};

  &:first-child {
    margin-right: 20px;
  }
`
interface SearchProps {
  tags: any
  isOpen: boolean
  searchResults?: Array<SearchBlockProps>
  searchCategories?: Array<{ to: string; text: string }>
  searchSuggestions: Array<SuggestionProps>
  searchValue: string
  onClickSuggestion?: (suggestion: string) => void
  onChange?: (event: any) => void
  onKeyDown?: (event: any) => void
  onRequestClear?: () => void
  onRequestClose?: () => void
}

const backdropRef = React.createRef<HTMLDivElement>()
const resultsRef = React.createRef<HTMLDivElement>()
const createResultSpacer = () => <ProductWrapper key={uniqueId()} />

const Search: React.FC<SearchProps> = ({
  tags,
  isOpen,
  searchValue,
  searchResults = [],
  searchSuggestions = [],
  onClickSuggestion,
  onChange,
  onKeyDown,
  onRequestClear,
  onRequestClose,
}: SearchProps) => {
  const modalRef: any = React.useRef(null)

  useClickAway(modalRef, () => {
    if (onRequestClose) {
      onRequestClose()
    }
  })

  const mobileInputRef: any = React.useRef(null)
  const desktopInputRef: any = React.useRef(null)

  React.useEffect(() => {
    if (!isOpen) {
      return
    }

    if (
      mobileInputRef &&
      mobileInputRef.current &&
      mobileInputRef.current.getBoundingClientRect().height > 0
    ) {
      mobileInputRef.current.focus()
    } else if (
      desktopInputRef &&
      desktopInputRef.current &&
      desktopInputRef.current.getBoundingClientRect().height > 0
    ) {
      desktopInputRef.current.focus()
    }
  }, [isOpen])

  const [pristine, setIsPristine] = React.useState(true)

  React.useEffect(() => {
    if (isOpen) {
      setIsPristine(true)
      setRenderedResultCount(20)
    }
  }, [isOpen])

  React.useEffect(() => {
    if (pristine && !R.isEmpty(searchValue)) {
      setIsPristine(false)
    }
  }, [searchValue])

  const [renderedResultCount, setRenderedResultCount] = React.useState(20)

  const validResults = searchResults.map((res) => {
    const u = { ...res }
    // include slug so we can click view story the image came from
    if (u["item"]["gallery"] && u["item"]["gallery"]["assets"]) {
      u["item"]["gallery"]["assets"] = u["item"]["gallery"]["assets"].map(
        (g) => ({
          ...g,
          story: { slug: res["item"].slug },
        })
      )
    }
    return u
  })

  const onScrollHandler = () => {
    if (
      typeof window === "undefined" ||
      !resultsRef ||
      !resultsRef.current ||
      validResults.length <= renderedResultCount
    ) {
      return
    }

    const resultNodes = resultsRef.current.childNodes
    const positionReference: any = resultNodes[resultNodes.length - 4]

    if (positionReference.getBoundingClientRect().top < window.innerHeight) {
      setRenderedResultCount(renderedResultCount + 20)
    }
  }

  React.useEffect((): any => {
    if (backdropRef && backdropRef.current) {
      backdropRef.current.addEventListener("scroll", onScrollHandler)
    }

    return () => {
      if (backdropRef && backdropRef.current) {
        backdropRef.current.removeEventListener("scroll", onScrollHandler)
      }
    }
  }, [isOpen, validResults, renderedResultCount])

  const renderedResults: any = R.take(renderedResultCount)(validResults)

  const [filter, setFilter] = React.useState<"STORIES" | "MEDIA">("STORIES")

  const entireGallery = React.useMemo(
    function () {
      // TODO: update this...
      return validResults.reduce((acc, val) => {
        // @ts-ignore
        if (val.item.gallery?.assets) {
          // @ts-ignore
          return [...acc, ...val.item.gallery?.assets]
        }
        return acc
      }, [])
    },
    [validResults]
  )

  return !isOpen ? null : (
    <Backdrop ref={backdropRef} isOpen={isOpen}>
      <Container ref={modalRef} isOpen={isOpen} showPlaceholder={!pristine}>
        <SearchWrapperMobileAndTablet>
          <SearchIconWrapper>
            <SearchIcon strokeColor={colors.black} />
          </SearchIconWrapper>
          <InputWrapper>
            <Input
              ref={mobileInputRef}
              value={searchValue}
              onChange={onChange}
              onKeyDown={onKeyDown}
              placeholder="Search Products"
            />
          </InputWrapper>
          <ClearButton onClick={onRequestClear}>
            <ClearIconWrapper>
              <CloseIcon strokeColor={colors.mediumDarkGray} strokeWidth="4" />
            </ClearIconWrapper>
          </ClearButton>
          <DoneButton onClick={onRequestClose}>Close</DoneButton>
        </SearchWrapperMobileAndTablet>
        <SearchWrapperDesktop>
          <SearchIconWrapper>
            <SearchIcon strokeColor={colors.black} />
          </SearchIconWrapper>
          <InputWrapper>
            <Input
              ref={desktopInputRef}
              value={searchValue}
              onChange={onChange}
              onKeyDown={onKeyDown}
              placeholder="Search UAG News"
            />
          </InputWrapper>
          <CloseButtonWrapper>
            <CloseButton onClick={onRequestClose}>
              <CloseIconWrapper>
                <CloseIcon strokeColor={colors.mediumDarkGray} />
              </CloseIconWrapper>
            </CloseButton>
          </CloseButtonWrapper>
        </SearchWrapperDesktop>
        {!validResults || R.isEmpty(validResults) ? (
          <Suggestions
            onClickSuggestion={onClickSuggestion}
            searchSuggestions={searchSuggestions}
            tags={tags}
          />
        ) : (
          <Results>
            <Title>FILTER CONTENT</Title>
            <FilterWrapper>
              <FilterButton
                active={filter === "STORIES"}
                onClick={() => setFilter("STORIES")}
              >
                stories
              </FilterButton>
              <FilterButton
                active={filter === "MEDIA"}
                onClick={() => setFilter("MEDIA")}
              >
                media
              </FilterButton>
            </FilterWrapper>
            <Divider />
            <Products ref={resultsRef}>
              {filter === "MEDIA" ? (
                <ExploreImageGrid
                  gallery={entireGallery}
                  onRequestCloseSearch={onRequestClose}
                />
              ) : (
                renderedResults.map(({ item }: any) => {
                  return (
                    <ProductWrapper key={item.slug}>
                      <SearchBlock
                        slug={item.slug}
                        name={item.title}
                        fluid={item.heroImage.fluid}
                        onClick={onRequestClose}
                        // item.onClick
                        tag={item.tag?.value}
                      />
                    </ProductWrapper>
                  )
                })
              )}
              {!R.isEmpty(renderedResults) &&
                R.times(createResultSpacer, 4 - (renderedResults.length % 4))}
            </Products>
          </Results>
        )}
        <ResultsPlaceholder isVisible={!pristine} />
      </Container>
    </Backdrop>
  )
}

export default Search
