import { Arrow } from "@components/icons"
import colors from "@constants/colors"
import React from "react"
import {
  ArrowIconWrapper,
  DropdownSvg,
  FileTypeIconWrapper,
  FlexSpace,
  IconWrapper,
  ImageAndOverlayWrapper,
  ImageWrapper,
  Overlay,
  TextAndArrowIconWrapper,
  ViewStoryText,
} from "./common"

export function GridPDF(props) {
  const pdfUrl =
    process.env.GATSBY_BASE_URL +
    "/images/" +
    props.file?.url?.split(`/${process.env.GATSBY_CONTENTFUL_SPACE_ID}/`)[1]

  return (
    <ImageAndOverlayWrapper index={props.index} hideIconOnHover={true}>
      <FileTypeIconWrapper>
        <PdfIcon />
      </FileTypeIconWrapper>

      <Overlay>
        <IconWrapper>
          <FlexSpace>
            {props.file?.url && (
              <a href={pdfUrl} download>
                <DropdownSvg
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.6509 18.2305C17.6509 18.2305 14.2846 18.2305 10.0003 18.2305C5.7159 18.2305 2.34961 18.2305 2.34961 18.2305C2.34961 18.2305 5.7159 18.2305 10.0003 18.2305C14.2846 18.2305 17.6509 18.2305 17.6509 18.2305Z"
                    stroke="#FFFEFD"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M10 1.01657L10 13.0083"
                    stroke="#FFFEFD"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M14.2324 9.48125L10 13.7136L5.76767 9.48125"
                    stroke="#FFFEFD"
                    strokeWidth="1.5"
                  />
                </DropdownSvg>
              </a>
            )}
            {props.slug && (
              <TextAndArrowIconWrapper
                to={"/" + props.slug}
                onClick={() => {
                  setTimeout(props.onRequestCloseSearch, 50)
                }}
              >
                <ViewStoryText>View Story</ViewStoryText>
                <ArrowIconWrapper>
                  <Arrow strokeColor={colors.offWhite} />
                </ArrowIconWrapper>
              </TextAndArrowIconWrapper>
            )}
          </FlexSpace>
        </IconWrapper>
      </Overlay>
      <ImageWrapper aspectRatio={props.image.aspectRatio} fluid={props.image} />
    </ImageAndOverlayWrapper>
  )
}

const PdfIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="1" fill="#FFFEFD" />
    <path
      d="M6.95996 18.72H17.04V8.72618L13.68 5.28003H6.95996V18.72Z"
      fill="black"
    />
    <path d="M12.8398 5.28003V9.48003H17.0398" stroke="#FFFEFD" />
  </svg>
)
