import React from "react"
import DesktopHeader from "./DesktopHeader"
import MobileHeader from "./MobileHeader"
import { DesktopOnly, MobileAndTabletOnly } from "../../utils/MediaQuery"

interface HeaderProps {
  supportLink: string
  onClickSearch?: () => void
  onClickMenu?: () => void
  onClickExplore?: () => void
  megaMenuHeaderRef?: any
  megaMenuHeaderItems?: Array<{
    label: string
    onClick?: () => void
  }>
  megaMenuActiveItem?: string
  expanded?: boolean
  showFixedHeader?: boolean
  fixedHeaderTopRef?: any
  FixedHeaderComponent?: React.ReactNode
  shortenDesktopHeader?: boolean
}

const Header = (props: HeaderProps) => (
  <>
    <DesktopOnly>
      <DesktopHeader {...props} />
    </DesktopOnly>
    <MobileAndTabletOnly>
      <MobileHeader {...props} />
    </MobileAndTabletOnly>
  </>
)

export default Header
