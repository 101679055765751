import * as R from "ramda"

export const isNilOrEmpty = (a: any): any => R.isNil(a) || R.isEmpty(a)

// Safely get nested prop
export const getIn = (o: object, path: string[] | string, defaultValue?: any) =>
  (o &&
    path &&
    R.view(R.lensPath(typeof path === "string" ? path.split(".") : path))(o)) ||
  defaultValue
