import { keyframes } from "styled-components"

export default {
  // From https://material.io/design/motion/speed.html#duration
  quick: "100ms",
  medium: "250ms",
  notQuiteLong: "300ms", // lol
  kindaLong: "350ms",
  long: "500ms",
}

export const cubicBezier = "cubic-bezier(0.15, 0.15, 0, 1)"
export const cubicBezierBackgroundColor = "cubic-bezier(0.15, 0.15, 0, 1)"
