import React from "react"
import DesktopMenu from "./DesktopMenu"
// import MobileMenu from "./MobileMenu"

const Menu = (props) => {
  return (
    <>
      <DesktopMenu {...props} />
      {/* <MobileMenu {...props} /> */}
    </>
  )
}

export default Menu
