import React from "react"
// import { connect } from "react-redux"
import styled, { css } from "styled-components"
import colors from "../../constants/colors"
import animation, { cubicBezier } from "../../constants/animation"
import breakpoints from "../../constants/breakpoints"
import Header from "../Header"
// import Footer from "../Footer/hoc/withContentfulData"
import Menu from "../Menu"
import Search from "../Search/hoc/withContentfulClient"
import CookieBanner from "../CookieBanner/hoc/withContentfulData"
import useModalBackgroundClose from "../../hooks/useModalBackgroundClose"
import useScrollHandler from "../../hooks/useScrollHandler"
import { getIn } from "../../utils"
import { getLocalStorage, writeLocalStorage } from "../../utils/storage"
import { COOKIE_POLICY_KEY } from "../../constants/storageKeys"
import { useStaticQuery, graphql } from "gatsby"

const getMainStyles = () => {
  const mobileOffset = 56 + 48
  const tabletOffset = 72 + 48
  const desktopOffset = 88 + 36
  return css`
    min-height: calc(100vh - ${mobileOffset}px - 410px);
    margin-top: ${mobileOffset}px;

    @media ${breakpoints.tablet} {
      min-height: calc(100vh - ${tabletOffset}px - 956px);
      margin-top: ${tabletOffset}px;
    }

    @media ${breakpoints.laptop} {
      min-height: calc(100vh - ${desktopOffset}px - 704px);
      margin-top: ${desktopOffset}px;
    }
  `
}

const OpenKeyboardLabel = styled.label`
  position: fixed;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
`

const OpenKeyboard = styled.textarea`
  position: fixed;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
`

const CookieBannerContainer = styled.div<{ showBanner: boolean }>`
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: ${colors.offWhite};
  transform: translateY(110%);
  transition: transform ${animation.long} ${cubicBezier};
  z-index: 100;

  ${({ showBanner }) =>
    showBanner &&
    `
      transform: translateY(0);
    `}
`

interface LayoutProps {
  data?: any
  children?: React.ReactNode
  showFixedHeader?: boolean
  fixedHeaderTopRef?: any
  FixedHeaderComponent?: React.ReactNode
}

const Layout = (props: LayoutProps) => {
  const [menu, setMenu] = React.useState(false)
  const [menuConfig, setMenuConfig] = React.useState<
    | {
        deviceType?: string
        brand?: string
        showShopOnly?: boolean
      }
    | undefined
  >(undefined)

  const openMenu = (config?) => {
    setMenu(true)

    if (config) {
      setMenuConfig(config)
    }
  }

  const closeMenu = () => {
    setMenu(false)
    setMenuConfig(undefined)
  }

  const [search, setSearch] = React.useState(false)

  const openSearch = () => {
    setSearch(true)
    const hiddenInput = document.getElementById("openMobileKeyboard")
    hiddenInput && hiddenInput.focus()
  }

  const closeSearch = () => {
    setSearch(false)
  }

  const filters = getIn(props, "data.filterData.filters")

  const [shortenDesktopHeader, setShortenDesktopHeader] = React.useState(false)

  const handleShortenHeight = () => {
    const html = document.getElementsByTagName("html")
    if (html && html[0] && html[0].getBoundingClientRect().top < -50) {
      setShortenDesktopHeader(true)
    } else {
      setShortenDesktopHeader(false)
    }
  }

  const scrollHandler = () => {
    handleShortenHeight()
  }

  useScrollHandler({
    onScroll: scrollHandler,
    runForProps: [],
  })

  const exploreMenuRef: any = React.useRef(null)
  const [showExploreMenu, setShowExploreMenu] = React.useState<boolean>(false)

  const toggleExploreMenu = () => {
    setShowExploreMenu(!showExploreMenu)
  }

  const closeExploreMenu = () => {
    setShowExploreMenu(false)
  }

  useModalBackgroundClose({
    modalRef: exploreMenuRef,
    isOpen: showExploreMenu,
    onRequestClose: () => {
      console.log("123")
      if (closeExploreMenu) {
        closeExploreMenu()
      }
    },
  })

  const [storageLoaded, setStorageLoaded] = React.useState<boolean>(false)
  const [showBanner, setShowBanner] = React.useState<boolean>(false)

  const acceptCookies = () => {
    writeLocalStorage(COOKIE_POLICY_KEY, true)
    setShowBanner(false)
  }

  const declineCookies = () => {
    writeLocalStorage(COOKIE_POLICY_KEY, false)
    setShowBanner(false)
  }

  React.useEffect(() => {
    if (!storageLoaded) {
      const cookiePolicy = getLocalStorage(COOKIE_POLICY_KEY)

      if (typeof cookiePolicy !== "boolean") {
        setShowBanner(true)
      }

      setStorageLoaded(true)
    }
  }, [])

  const data = useStaticQuery(
    graphql`
      query QueryAllContentfulPost {
        allContentfulPost {
          edges {
            node {
              id
              slug
              title
              createdAt
              tag {
                id
                value
              }
              heroImage {
                ...ImageAsset
              }
              gallery {
                id
                archiveZip {
                  id
                  title
                  description
                  file {
                    url
                    fileName
                    contentType
                  }
                }
                assets {
                  ... on ContentfulGalleryImage {
                    id
                    asset {
                      id
                      title
                      description
                      contentful_id
                      file {
                        url
                        fileName
                        contentType
                      }
                      fluid {
                        aspectRatio
                        src
                        srcSet
                        srcSetWebp
                        srcWebp
                        sizes
                      }
                    }
                    __typename
                  }
                  ... on ContentfulGalleryPdf {
                    id
                    pdf {
                      id
                      title
                      description
                      contentful_id
                      file {
                        url
                        fileName
                        contentType
                      }
                      fluid {
                        srcWebp
                        srcSetWebp
                        srcSet
                        sizes
                        src
                        aspectRatio
                      }
                    }
                    displayImage {
                      id
                      title
                      description
                      contentful_id
                      fluid {
                        aspectRatio
                        sizes
                        src
                        srcSet
                        srcSetWebp
                        srcWebp
                      }
                    }
                    __typename
                  }
                  ... on ContentfulGalleryVideo {
                    id
                    title
                    description
                    contentful_id
                    playVideoLink
                    lowResDownloadLink
                    highResDownloadLink
                    thumbnail {
                      id
                      title
                      description
                      fluid {
                        src
                        sizes
                        aspectRatio
                        srcSet
                        srcSetWebp
                        srcWebp
                      }
                      contentful_id
                    }
                    __typename
                  }
                }
              }
            }
          }
        }
        allContentfulPostTag {
          nodes {
            id
            value
          }
        }
        contentfulGlobalSettings {
          cookieBannerHeader
          cookieBannerBody {
            raw
          }
          cookieBannerAcceptText
          cookieBannerDeclineText
        }
      }

      fragment ImageAsset on ContentfulAsset {
        id
        title
        description
        contentful_id
        file {
          url
          fileName
          contentType
        }
        fluid(quality: 80) {
          sizes
          src
          srcSet
          srcWebp
          srcSetWebp
          aspectRatio
        }
        __typename
      }
    `
  )

  return (
    <div>
      <Header
        supportLink={""}
        onClickSearch={openSearch}
        onClickMenu={() => openMenu()}
        onClickExplore={toggleExploreMenu}
        // showFilters={contentfulProduct !== undefined}
        showFixedHeader={getIn(props, "showFixedHeader", undefined)}
        fixedHeaderTopRef={getIn(props, "fixedHeaderTopRef", undefined)}
        FixedHeaderComponent={getIn(props, "FixedHeaderComponent", undefined)}
        shortenDesktopHeader={shortenDesktopHeader}
      />
      {/* <Footer legalLinks={{}} /> */}
      <Menu
        data={props.data}
        isOpen={menu}
        config={menuConfig}
        filterFunnel={getIn(props, "data.menuData.filterFunnel", {})}
        globalData={getIn(props, "data.globalData", {})}
        menuData={getIn(props, "data.menuData", {})}
        onRequestClose={closeMenu}
      />
      <Search
        isOpen={search}
        tags={getIn(data, "allContentfulPostTag.nodes", [])}
        searchSuggestions={getIn(data, "allContentfulPost.edges", [])}
        onRequestClose={closeSearch}
      />
      <OpenKeyboardLabel htmlFor="openMobileKeyboard" />
      <OpenKeyboard id="openMobileKeyboard" />
      <CookieBannerContainer showBanner={showBanner}>
        <CookieBanner
          showBanner={showBanner}
          onAccept={acceptCookies}
          onDecline={declineCookies}
        />
      </CookieBannerContainer>
      {props.children}
    </div>
  )
}

export default Layout
