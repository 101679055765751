import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import colors from "@constants/colors"
import breakpoints from "@constants/breakpoints"
import { UAGLogo, ULogo } from "@components/icons"
import { body16MainStyles } from "@constants/typography"
const Container = styled.div`
  align-items: center;
  justify-content: center;
  display: inline-flex;
`

const Button = styled.button`
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0;
  outline: none;
  border: none;
  background: none;

  &:focus,
  &:active {
    outline: none;
  }

  @media ${breakpoints.laptop} {
    &:hover {
      cursor: pointer;
    }
  }
`

const LogoWrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`

const UAGLogoWrapper = styled(LogoWrapper)`
  width: 52.12px;

  @media ${breakpoints.tablet} {
    width: 75px;
  }

  @media ${breakpoints.laptop} {
    width: 75px;
  }
`

const NewsText = styled.span`
  ${body16MainStyles}
`

const Divider = styled.div`
  width: 1px;
  height: 23.5px;
  margin: 0 12px;

  background-color: ${colors.black};

  @media ${breakpoints.tablet} {
    height: 33.5px;
  }

  @media ${breakpoints.laptop} {
    height: 33.5px;
  }
`

interface LogoProps {}

const Logo: React.FC<LogoProps> = () => {
  return (
    <Container>
      <Link to="/">
        <Button>
          <UAGLogoWrapper>
            <UAGLogo strokeColor={colors.black} />
          </UAGLogoWrapper>
        </Button>
      </Link>
      <Divider />
      <Link to="/">
        <NewsText>News</NewsText>
      </Link>
    </Container>
  )
}

export default Logo
