import React from "react"
import styled from "styled-components"
import colors from "../../constants/colors"
import animation, { cubicBezier } from "../../constants/animation"
import breakpoints from "../../constants/breakpoints"
import {
  body16MainStyles,
  smallcapsBold13Styles,
} from "../../constants/typography"
import { Search } from "../icons"
// import MegaMenuHeader from "../MegaMenu/MegaMenuHeader"
import { DesktopHeaderProps } from "./DesktopHeader"
import { getIn } from "../../utils"
// import {
//   getShopURL,
//   getShopURLForDevice,
//   getShopURLForDeviceType,
//   getShopURLForBrandDeviceType,
// } from "../../utils/urls"
import * as R from "ramda"

const SearchContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
`

const SearchButtonText = styled.div`
  ${body16MainStyles}
  display: none;
  margin-left: 10px;
  opacity: 0.5;

  @media ${breakpoints.largeLaptop} {
    display: initial;
  }
`

const SearchButton = styled.button`
  display: flex;
  margin-right: calc(1 / 12 * 100vw);
  padding: 0 calc(0.25 / 12 * 100vw) 0 calc(0.25 / 12 * 100vw - 6px);
  align-items: center;
  justify-content: center;
  will-change: background-color;
  transition: background-color ${animation.medium} ${cubicBezier};
  background: none;
  border: none;
  &:focus {
    outline: none;
  }

  @media ${breakpoints.laptop} {
    &:hover {
      cursor: pointer;
      background-color: ${colors.dividerLineGray};
    }
  }

  @media ${breakpoints.largeLaptop} {
    margin-right: 0;
  }

  @media ${breakpoints.largeDesktop} {
    padding: 0 40px 0 36px;
  }
`

const Icon = styled.div`
  margin-top: -3px;
  width: 24px;
  height: 24px;
`

const Explore = styled.a`
  ${smallcapsBold13Styles}
  display: flex;
  height: 100%;
  margin-left: auto;
  padding: 4px calc(0.25 / 12 * 100vw) 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  will-change: background-color;
  transition: background-color ${animation.medium} ${cubicBezier};
  background-color: transparent;
  border: none;
  outline: none;

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  @media ${breakpoints.laptop} {
    &:hover {
      cursor: pointer;
      background-color: ${colors.dividerLineGray};
    }
  }

  @media ${breakpoints.largeDesktop} {
    width: 106px;
    margin-right: 8px;
    padding: 4px 0 0;
  }
`

const DefaultHeaderContent: React.FC<DesktopHeaderProps> = (
  props: DesktopHeaderProps
) => {
  // const showFilters = !R.isEmpty(props.filters) && props.showFilters
  // const deviceType = getIn(props, "filters.deviceType")
  // const brand = getIn(props, "filters.brand")
  // const device = getIn(props, "filters.device")
  // const series = getIn(props, "filters.series")

  return (
    <>
      <SearchContainer>
        <SearchButton onClick={props.onClickSearch}>
          <Icon>
            <Search />
          </Icon>
          <SearchButtonText>Search for articles or products</SearchButtonText>
        </SearchButton>
      </SearchContainer>
      {/* {showFilters ? (
        <FilterContainer>
          <FilterWrapper>
            <FilterTag text="All" to={getShopURL()} />
          </FilterWrapper>

          {deviceType && (
            <FilterWrapper>
              <FilterTag
                text={deviceType.menuLabel || deviceType.name}
                to={getShopURLForDeviceType(deviceType.slug)}
              />
            </FilterWrapper>
          )}
          {deviceType && brand && (
            <FilterWrapper>
              <FilterTag
                text={brand.name}
                to={getShopURLForBrandDeviceType(brand.slug, deviceType.slug)}
              />
            </FilterWrapper>
          )}
          {deviceType && brand && device && (
            <FilterWrapper>
              <FilterTag
                text={device.name}
                to={getShopURLForDevice(device.slug)}
              />
            </FilterWrapper>
          )}
          {deviceType && brand && device && series && (
            <SeriesTagWrapper>
              <FilterTag text={series.name} />
            </SeriesTagWrapper>
          )}
        </FilterContainer>
      ) : ( */}
      {/* )} */}
      {/* <MegaMenuHeader
          headerRef={getIn(props, "megaMenuHeaderRef")}
          items={getIn(props, "megaMenuHeaderItems", [])}
          active={getIn(props, "megaMenuActiveItem")}
        /> */}
      <Explore href="https://urbanarmorgear.com">Shop UAG</Explore>
    </>
  )
}

export default DefaultHeaderContent
