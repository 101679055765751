import { Arrow } from "@components/icons"
import colors from "@constants/colors"
import React from "react"

import {
  ArrowIconWrapper,
  DownloadDropdown,
  DropdownArrow,
  DropdownSvg,
  DropdownWrapper,
  FlexSpace,
  IconWrapper,
  ImageAndOverlayWrapper,
  ImageWrapper,
  Overlay,
  TextAndArrowIconWrapper,
  ViewStoryText,
} from "./common"

export const GridImage = (props) => {
  const [showDropdown, setShowDropdown] = React.useState(false)
  return (
    <ImageAndOverlayWrapper
      image={true}
      index={props.index}
      onMouseLeave={() => setShowDropdown(false)}
    >
      <Overlay>
        <IconWrapper>
          <FlexSpace>
            <DropdownSvg
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <path
                d="M17.6509 18.2305C17.6509 18.2305 14.2846 18.2305 10.0003 18.2305C5.7159 18.2305 2.34961 18.2305 2.34961 18.2305C2.34961 18.2305 5.7159 18.2305 10.0003 18.2305C14.2846 18.2305 17.6509 18.2305 17.6509 18.2305Z"
                stroke="#FFFEFD"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
              <path
                d="M10 1.01657L10 13.0083"
                stroke="#FFFEFD"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
              <path
                d="M14.2324 9.48125L10 13.7136L5.76767 9.48125"
                stroke="#FFFEFD"
                strokeWidth="1.5"
              />
            </DropdownSvg>
            {props.slug && (
              <TextAndArrowIconWrapper
                to={"/" + props.slug}
                onClick={() => {
                  setTimeout(props.onRequestCloseSearch, 50)
                }}
              >
                <ViewStoryText>View Story</ViewStoryText>
                <ArrowIconWrapper>
                  <Arrow strokeColor={colors.offWhite} />
                </ArrowIconWrapper>
              </TextAndArrowIconWrapper>
            )}
          </FlexSpace>
          {showDropdown && (
            <DropdownWrapper>
              <DropdownArrow />
              <DownloadDropdown>
                <a
                  download={props.file.fileName}
                  href={getDownloadableImageURL(props.file.url)}
                  onClick={() => {
                    setShowDropdown(false)
                  }}
                >
                  Download Low Res
                </a>
                <a
                  download={props.file.fileName}
                  href={getDownloadableImageURL(props.file.url)}
                  onClick={() => {
                    setShowDropdown(false)
                  }}
                >
                  Download High Res
                </a>
              </DownloadDropdown>
            </DropdownWrapper>
          )}
        </IconWrapper>
      </Overlay>
      <ImageWrapper aspectRatio={props.image.aspectRatio} fluid={props.image} />
    </ImageAndOverlayWrapper>
  )
}

/**
 * Convert provided CDN url to url with site base path (to avoid CORS)
 *
 * @param url e.g. https://images.ctfassets.net/rpuhbrco53gc/random1/random2/filename.png
 * @return parsedUrl - e.g. https://news.uag.com/images/random1/random2/filename.png
 */
function getDownloadableImageURL(url: string): string {
  const spaceId = process.env.GATSBY_CONTENTFUL_SPACE_ID || "rpuhbrco53gc"
  const [base, spaceIdSuffix] = url.split(spaceId)
  return spaceIdSuffix
}
