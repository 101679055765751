import React from "react"
import styled, { css } from "styled-components"
import { throttle } from "lodash"
import colors from "@constants/colors"
import animation, { cubicBezier } from "@constants/animation"
import breakpoints from "@constants/breakpoints"
import {
  body16BoldStyles,
  body16MainStyles,
  body14Styles,
  smallcapsBold14Styles,
  smallcapsBold13Styles,
  subtextStyles,
} from "@constants/typography"
import { Chevron } from "../icons"

const baseButtonStyles = css`
  outline: none;
  border: none;
  background: none;

  &:hover {
    cursor: pointer;
  }

  &:focus,
  &:active {
    outline: none;
  }
`

const Container = styled.div<{ showBanner: boolean }>`
  transition: visibility 0ms linear ${animation.long};
  visibility: hidden;
  padding: 32px 20px 54px;
  border-top: solid 1px ${colors.dividerLineGray};

  ${({ showBanner }) =>
    showBanner &&
    css`
      transition: none;
      visibility: initial;
    `}

  @media ${breakpoints.tablet} {
    padding: 40px 40px 52px;
  }

  @media ${breakpoints.laptop} {
    display: flex;
    padding: 40px calc(1 / 12 * 100vw);
  }
`

const Content = styled.div`
  @media ${breakpoints.laptop} {
    flex-direction: column;
    display: flex;
    width: calc(6 / 12 * 100vw);
  }
`

const HeaderWrapper = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
`

const Header = styled.div`
  ${body16BoldStyles}
  width: 196px;
  color: ${colors.black};

  @media ${breakpoints.tablet} {
    width: 100%;
  }

  @media ${breakpoints.laptop} {
    width: 100%;
  }
`

const InfoButton = styled.button<{ expanded: boolean }>`
  ${baseButtonStyles}
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 74px;
  height: 40px;
  padding: 0 10px;
  border-radius: 2px;
  background-color: ${colors.dividerLineGray};

  @media ${breakpoints.tablet} {
    display: none;
  }

  @media ${breakpoints.laptop} {
    display: none;
  }
`

const InfoButtonText = styled.span`
  padding-top: 2px;
  font-family: "Interstate Regular", Helvetica, Arial, sans-serif;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: ${colors.black};
`

const IconWrapper = styled.div<{ expanded: boolean }>`
  transition: transform ${animation.medium} ${cubicBezier};
  width: 24px;
  height: 24px;
  ${({ expanded }) =>
    css`
      transform: translateX(6px) rotate(${expanded ? "270deg" : "90deg"});
    `}
`

const BodyWrapper = styled.div<{ expanded: boolean; bodyHeight: number }>`
  ${({ expanded, bodyHeight }) => css`
    overflow: hidden;
    transition: height ${animation.medium} ${cubicBezier};
    height: ${expanded ? `${bodyHeight + 24}px` : "0px"};
    /* height: ${expanded ? `${bodyHeight + 24 + 40}px` : "0px"}; */
    padding: 0;

    @media ${breakpoints.tablet} {
      height: auto;
      padding-top: 12px;
    }

    @media ${breakpoints.laptop} {
      height: auto;
      padding-top: 12px;
    }
  `}
`

const Body = styled.div<{ expanded: boolean }>`
  ${({ expanded }) => css`
    ${subtextStyles}
    transition: padding ${animation.medium} ${cubicBezier};
    padding: ${expanded ? "24px" : 0} 24px 0 0;
    color: ${colors.mediumDarkGray};

    p,
    a {
      margin: 0;
    }

    a {
      text-decoration-line: underline;
    }

    @media ${breakpoints.tablet} {
      ${body16MainStyles}
      padding-top: 0;
      color: ${colors.mediumDarkGray};
    }

    @media ${breakpoints.laptop} {
      ${body14Styles}
      padding-top: 0;
      color: ${colors.mediumDarkGray};
    }
  `}
`

const Buttons = styled.div`
  @media ${breakpoints.tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media ${breakpoints.laptop} {
    display: flex;
    width: calc(4 / 12 * 100vw);
    padding-top: 32px;
    flex-direction: row-reverse;
    align-items: center;
    /* justify-content: flex-end; */
    justify-content: center;
  }
`

const DeclineButton = styled.button`
  ${baseButtonStyles}
  display: flex;
  height: 40px;
  padding: 0;
  align-items: center;
  justify-content: center;

  @media ${breakpoints.tablet} {
    height: 48px;
    margin-top: 26px;
  }

  @media ${breakpoints.laptop} {
    width: 176px;
    flex-grow: 0;
  }
`

const DeclineButtonText = styled.span`
  padding-top: 2px;
  font-family: "Interstate Regular", Helvetica, Arial, sans-serif;
  font-size: 11px;
  color: ${colors.mediumGray};
  line-height: 16px;
  letter-spacing: 0.8px;
  text-decoration-line: underline;
  text-transform: uppercase;

  @media ${breakpoints.tablet} {
    ${smallcapsBold14Styles};
    padding-top: 4px;
    color: ${colors.mediumGray};
    text-decoration: none;
  }

  @media ${breakpoints.laptop} {
    ${smallcapsBold13Styles};
    padding-top: 3px;
    color: ${colors.mediumGray};
    text-decoration: none;
  }
`

const AcceptButton = styled.button`
  ${baseButtonStyles}
  display: flex;
  width: 100%;
  height: 48px;
  margin-top: 28px;
  align-items: center;
  justify-content: center;
  background-color: ${colors.black};
  border-radius: 2px;

  @media ${breakpoints.tablet} {
    width: 226px;
    margin-top: 42px;
    flex-grow: 0;
  }

  @media ${breakpoints.laptop} {
    width: 226px;
    margin-top: 0;
    margin-left: 24px;
    flex-grow: 0;
  }
`

const AcceptButtonText = styled.span`
  ${smallcapsBold14Styles};
  padding-top: 4px;
  color: ${colors.offWhite};
`

export interface CookieBannerProps {
  showBanner: boolean
  header: string
  body: React.ReactNode
  acceptText: string
  declineText: string
  onAccept: () => void
  onDecline: () => void
}

const CookieBanner: React.FC<CookieBannerProps> = ({
  showBanner,
  header,
  body,
  acceptText,
  declineText,
  onAccept,
  onDecline,
}) => {
  const [expanded, setExpanded] = React.useState<boolean>(false)
  const [bodyHeight, setBodyHeight] = React.useState<number>(0)
  const bodyRef = React.useRef<HTMLDivElement>(null)

  const getBodyHeight = () => {
    return bodyRef && bodyRef.current
      ? bodyRef.current.getBoundingClientRect().height
      : 0
  }

  const onResize = () => {
    setBodyHeight(getBodyHeight())
  }

  const onResizeThrottled = throttle(onResize, 100)

  React.useEffect(() => {
    document.addEventListener("resize", onResizeThrottled)

    return () => {
      document.removeEventListener("resize", onResizeThrottled)
    }
  }, [])

  React.useEffect(() => {
    setBodyHeight(getBodyHeight())
  }, [bodyRef, body])

  return (
    <Container showBanner={showBanner}>
      <Content>
        <HeaderWrapper>
          <Header>{header}</Header>
          <InfoButton
            expanded={expanded}
            onClick={() => {
              setExpanded(!expanded)
            }}
          >
            <InfoButtonText>Info</InfoButtonText>
            <IconWrapper expanded={expanded}>
              <Chevron />
            </IconWrapper>
          </InfoButton>
        </HeaderWrapper>
        <BodyWrapper expanded={expanded} bodyHeight={bodyHeight}>
          <Body ref={bodyRef} expanded={expanded}>
            {body}
          </Body>
          {/* <MobileOnly>
            <DeclineButton onClick={onDecline}>
              <DeclineButtonText>{declineText}</DeclineButtonText>
            </DeclineButton>
          </MobileOnly> */}
        </BodyWrapper>
      </Content>
      <Buttons>
        <AcceptButton onClick={onAccept}>
          <AcceptButtonText>{acceptText}</AcceptButtonText>
        </AcceptButton>
        {/* <TabletAndDesktopOnly>
          <DeclineButton onClick={onDecline}>
            <DeclineButtonText>{declineText}</DeclineButtonText>
          </DeclineButton>
        </TabletAndDesktopOnly> */}
      </Buttons>
    </Container>
  )
}

export default CookieBanner
