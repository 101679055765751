import Video from "@components/Video"
import React from "react"
import styled from "styled-components"
import { FileTypeIconWrapper, ImageAndOverlayWrapper } from "./common"

const NoStyleButton = styled.div`
  outline: none;
  border: none;
  background: none;

  &:hover {
    cursor: pointer;
  }

  &:focus,
  &:active {
    outline: none;
  }
`

export const GridVideo = (props) => {
  const videoRef = React.useRef<HTMLVideoElement | null>(null)
  const [showPlayVideoButton, setShowPlayVideoButton] = React.useState(true)

  return (
    <ImageAndOverlayWrapper index={props.index} hideIconOnHover={false}>
      {!videoRef.current || videoRef.current?.paused ? (
        <FileTypeIconWrapper>
          <NoStyleButton
            onClick={() => {
              videoRef.current?.play()
              setShowPlayVideoButton(false)
            }}
          >
            <VideoIcon />
          </NoStyleButton>
        </FileTypeIconWrapper>
      ) : null}

      <Video
        // poster={props.image.src}
        mobileSrc={props.playVideoLink}
        src={props.playVideoLink}
        preload="auto"
        controls={!showPlayVideoButton}
        muted={true}
        loop={false}
        autoPlay={false}
        playsInline={true}
        ref={videoRef}
      />
    </ImageAndOverlayWrapper>
  )
}

const VideoIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="1" fill="#FFFEFD" />
    <path
      d="M8.57178 5.57129L17.1432 11.9999L8.57178 18.4284V5.57129Z"
      fill="black"
    />
  </svg>
)
