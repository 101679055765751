import React from "react"
import { compose } from "recompose"
import Fuse from "fuse.js"
//
import Search from "../index"
import { getIn } from "@utils/index"

const ESCAPE_KEY = "Escape"
const ENTER_KEY = "Enter"

const GRAPHQL_SCHEMA_INTERNAL_TITLE = "GraphQL schema - do not delete"

const searchDataInitialState = {
  productIds: [],
  devices: [],
  brandDeviceTypes: [],
  products: [],
}

const withContentfulClient: any = (WrappedComponent: any) => (props: any) => {
  const [isFetching, setIsFetching] = React.useState(false)
  const [searchData, setSearchData] = React.useState(searchDataInitialState)
  const [searchResults, setSearchResults] = React.useState([])
  const [searchCategories, setSearchCategories] = React.useState([])
  const [searchValue, setValue] = React.useState("")

  const onKeyDown = (event: any) => {
    if (event.key === ESCAPE_KEY) {
      props.onRequestClose()
    }
    if (event.key === ENTER_KEY && event.currentTarget) {
      event.currentTarget.blur()
    }
  }

  const onClickSuggestion = (suggestion) => {
    if (suggestion) {
      setValue(suggestion)
    }
  }

  const onChange = (event) => {
    setValue(getIn(event, "target.value", ""))
  }

  const clearData = () => {
    setValue("")
    setSearchData(searchDataInitialState)
    setSearchResults([])
    setSearchCategories([])
  }

  React.useEffect(() => {
    if (searchData.productIds.length) {
      setSearchResults(searchData.products) as any
    } else {
      setSearchResults([])
      setSearchCategories([])
    }
  }, [searchData])

  React.useEffect(() => {
    if (searchValue.length) {
      const fuse = new Fuse(
        props.searchSuggestions.map(({ node }) => node),
        {
          keys: [
            "title",
            "tag.value",
            // video
            "gallery.assets.title",
            "gallery.assets.description",
            // images
            "gallery.assets.asset.title",
            "gallery.assets.asset.description",
            "gallery.assets.asset.file.fileName",
            // pdf
            "gallery.assets.pdf.title",
            "gallery.assets.pdf.description",
            "gallery.assets.pdf.file.fileName",
          ],
        }
      )
      setSearchResults(fuse.search(searchValue) as any)
    } else {
      clearData()
    }
  }, [searchValue])

  React.useEffect(() => {
    if (!props.isOpen) {
      clearData()
    }
  }, [props.isOpen])

  return (
    <WrappedComponent
      isFetching={isFetching}
      searchValue={searchValue}
      searchResults={searchResults}
      tags={props.tags}
      searchCategories={searchCategories}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onRequestClear={clearData}
      onClickSuggestion={onClickSuggestion}
      {...props}
    />
  )
}

export default compose(withContentfulClient)(Search) as any
