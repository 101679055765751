const devices = {
  mobile: 320,
  tablet: 600,
  largeTablet: 801,
  largeTabletMax: 1024,
  laptop: 801,
  largeLaptop: 1201,
  desktop: 1441,
  largeDesktop: 1921,
}

const tabletQuery = `(min-width: ${devices.tablet}px) and (max-width: ${
  devices.largeTablet - 1
}px)`

const largeTabletQuery = `(min-width: ${devices.largeTablet}px) and (max-width: ${devices.largeTabletMax}px) and (orientation: portrait)`

const laptopQuery = `(min-width: ${
  devices.laptop
}px) and (orientation: landscape), (min-width: ${devices.largeTabletMax + 1}px)`

export default {
  mobileOnly: `(max-width: ${devices.tablet - 1}px)`,
  largerThanMobile: `(min-width: ${devices.tablet}px)`,
  tablet: `${tabletQuery}, ${largeTabletQuery}`,
  laptop: laptopQuery,
  largeLaptop: `(min-width: ${devices.largeLaptop}px)`,
  desktop: `(min-width: ${devices.desktop}px)`,
  largeDesktop: `(min-width: ${devices.largeDesktop}px)`,
  mobileAndTabletOnly: `(max-width: ${
    devices.tablet - 1
  }px), ${tabletQuery}, ${largeTabletQuery}`,
}
