import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import CookieBanner, { CookieBannerProps } from "../index"
import { getIn } from "../../../utils"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const withContentfulData: any = (WrappedComponent: any) => (
  props: CookieBannerProps
) => {
  const data = useStaticQuery(
    graphql`
      query CookieBannerFields {
        contentfulGlobalSettings {
          cookieBannerHeader
          cookieBannerBody {
            raw
          }
          cookieBannerAcceptText
          cookieBannerDeclineText
        }
      }
    `
  )

  const {
    cookieBannerHeader,
    cookieBannerBody,
    cookieBannerAcceptText,
    cookieBannerDeclineText,
  } = getIn(data, "contentfulGlobalSettings", {})
  const bodyRichText = getIn(cookieBannerBody, "raw")
  const cookieBannerData: CookieBannerProps = {
    ...props,
    header: cookieBannerHeader,
    body: renderRichText(cookieBannerBody, {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      },
    }),
    acceptText: cookieBannerAcceptText,
    declineText: cookieBannerDeclineText,
  }

  return <WrappedComponent {...cookieBannerData} />
}

export default withContentfulData(CookieBanner) as any
