import breakpoints from "@constants/breakpoints"
import { createGlobalStyle } from "styled-components"
import "sanitize.css"
import "@assets/fonts/stylesheet.css"

const GlobalStyle = createGlobalStyle`
  html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  body {
    background-color: #ffffff;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  button,
  div {
    margin: 0;
    font-size: inherit;
  }

  a {
    text-decoration: inherit;
    color: inherit;
  }

  button,
  select,
  input,
  textarea {
    font: inherit;
  }

  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    
    width: calc(100% + 30px);
    margin-left: -30px; /* gutter size offset */
    @media (${breakpoints.mobileOnly}) {
      margin-left: 0;
    }
  }

  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    @media (${breakpoints.mobileOnly}) {
      padding-left: 0; 
    }
    background-clip: padding-box;
  }
`

export default GlobalStyle
