import React from "react"
import styled from "styled-components"
import breakpoints from "@constants/breakpoints"
import colors from "@constants/colors"
import { smallcapsRegular13Styles } from "@constants/typography"
import { Cameron } from "../icons"
import { Link } from "gatsby"

export const Container = styled.button`
  display: flex;
  width: fit-content;
  min-height: 40px;
  padding: 0 2px 0 16px;
  flex-direction: row;
  align-items: center;
  background: ${colors.lightGray};
  border: none;
  cursor: pointer;

  @media ${breakpoints.laptop} {
    background: transparent;
    border: 1px solid ${colors.mediumGray};
    border-radius: 2px;
  }

  &:focus {
    outline: none;
  }
`

export const ContainerFilled = styled(Container)`
  min-height: 32px;

  @media ${breakpoints.laptop} {
    background: ${colors.dividerLineGray};
    border: none;
  }
`

// Interstate padding
export const Text = styled.div<{ light?: boolean }>`
  ${smallcapsRegular13Styles}
  display: flex;
  min-height: 40px;
  margin-right: 2px;
  padding-top: 2px;
  align-items: center;
  font-size: 11px;
  color: ${(props) =>
    props.light ? colors.mediumGray : colors.mediumDarkGray};
  line-height: 16px;
  letter-spacing: 0.8px;
  text-align: start;

  @media ${breakpoints.laptop} {
    color: ${(props) =>
      props.light ? colors.mediumGray : colors.mediumDarkGray};
  }

  ${ContainerFilled} & {
    min-height: 32px;
  }
`

export const TextPadding = styled.div`
  width: 12px;
`

export const Icon = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  flex-shrink: 0;

  @media ${breakpoints.laptop} {
    width: 24px;
    height: 24px;
  }
`

interface FilterTagProps {
  text: string
  to?: string
  onClick?: any
}

const renderFilterTag = (props: FilterTagProps) => (
  <Container onClick={props.onClick}>
    <Text>{props.text}</Text>
    <TextPadding />
  </Container>
)

const renderLinkTag = (props: FilterTagProps) => (
  <Link to={props.to || ""} onClick={props.onClick}>
    <ContainerFilled>
      <Text>{props.text}</Text>
      <Icon>
        <Cameron strokeColor={colors.mediumDarkGray} />
      </Icon>
    </ContainerFilled>
  </Link>
)

const renderTag = (props: FilterTagProps) => (
  <ContainerFilled style={{ cursor: "auto" }}>
    <Text light={true}>{props.text}</Text>
    <TextPadding />
  </ContainerFilled>
)

const FilterTag = (props: FilterTagProps) => {
  if (props.to) {
    return renderLinkTag(props)
  } else if (props.onClick) {
    return renderFilterTag(props)
  }
  return renderTag(props)
}

export default FilterTag
