import animation, { cubicBezier } from "@constants/animation"
import { body16MainStyles } from "@constants/typography"
import { Link } from "gatsby"
import GatsbyImage, { GatsbyImageProps } from "gatsby-image"
import styled from "styled-components"

export const Overlay = styled.div<{ hide?: boolean }>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 15px 20px;
  position: absolute;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.65);

  display: flex;
  align-items: flex-end;

  &:hover {
    opacity: ${({ hide }) => (hide ? 0 : 1)};
    z-index: ${({ hide }) => (hide ? -1000 : 1)};
    cursor: pointer;
  }
`

export const IconWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const DropdownArrow = styled.div`
  content: "";
  height: 20;
  width: 20;
  backgroundcolor: black;
  transform: rotate(45deg) translateX(37.5px) translateY(37.5px);
`

export const DownloadDropdown = styled.div`
  position: absolute;
  background-color: black;
  top: calc(100% + 10px);
  padding: 20px 0;
  transform: translateX(-10px) translateY(5px);

  > a {
    ${body16MainStyles}
    display: block;
    color: white;
    padding: 0 20px;

    &:nth-child(1) {
      padding-bottom: 16px;
    }

    &:nth-child(2) {
      border-top: solid 1px #404040;
      padding-top: 16px;
    }
  }
`

export const DropdownWrapper = styled.div``

export const DropdownSvg = styled.svg`
  &:hover ~ {
    opacity: 1;
  }
`

export const ImageWrapper = styled(GatsbyImage)<
  GatsbyImageProps & { aspectRatio: number }
>`
  height: ${({ aspectRatio }) => (aspectRatio > 3 / 2 ? 400 : 185)};
  background: grey;
  margin-bottom: 30px;
`

export const FileTypeIconWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10000;
`

export const ImageAndOverlayWrapper = styled.div<{
  index: number
  image?: boolean
  hideIconOnHover?: boolean
}>`
  position: relative;
  z-index: ${({ index }) => 1000 - index};

  ${({ hideIconOnHover }) => {
    if (hideIconOnHover) {
      return `
        &:hover ${FileTypeIconWrapper} {
          display: none;
        }
      `
    }
  }}
`

export const ViewStoryText = styled.p`
  ${body16MainStyles}
  color: white;
`
export const TextAndArrowIconWrapper = styled(Link)`
  display: flex;
  align-items: center;
`

export const ArrowIconWrapper = styled.div`
  margin-top: -4px;
  margin-left: 4px;
  width: 20px;
  height: 20px;
  will-change: transform;
  transition: transform ${animation.quick} ${cubicBezier};
  transform: translateX(0);
`

export const FlexSpace = styled.div`
  display: flex;
  justify-content: space-between;
`
