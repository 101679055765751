import colors from "./colors"

export const h1Styles = `
  margin: 0;
  font-family: "Interstate Bold", Helvetica, Arial, sans-serif;
  font-size: 90px;
  font-weight: 400;
  color: ${colors.black};
  line-height: 140px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
`

export const h2Styles = `
  margin: 0;
  font-family: "Interstate Bold", Helvetica, Arial, sans-serif;
  font-size: 64px;
  font-weight: 400;
  color: ${colors.black};
  line-height: 72px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
`

export const h3Styles = `
  margin: 0;
  font-family: "Interstate Bold", Helvetica, Arial, sans-serif;
  font-size: 38px;
  font-weight: 400;
  color: ${colors.black};
  line-height: 48px;
  letter-spacing: 2px;
  text-transform: uppercase;
`

export const h4Styles = `
  margin: 0;
  font-family: "Interstate Bold", Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-weight: 400;
  color: ${colors.black};
  line-height: 32px;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const h5Styles = `
  margin: 0;
  font-family: "Interstate Bold", Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: ${colors.black};
  line-height: 28px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`

export const smallcapsBold14Styles = `
  font-family: "Interstate Bold", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: ${colors.black};
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const smallcapsBold13Styles = `
  font-family: "Interstate Bold", Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: ${colors.black};
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const smallcapsRegular14Styles = `
  font-family: "Interstate Regular", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: ${colors.black};
  line-height: 19px;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const smallcapsRegular13Styles = `
  font-family: "Interstate Regular", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: ${colors.black};
  line-height: 19px;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const smallcapsRegular11Styles = `
  font-family: "Interstate Regular", Helvetica, Arial, sans-serif;
  font-size: 11px;
  color: ${colors.black};
  line-height: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
`

export const body28Styles = `
  font-family: "Avenir Roman", Helvetica, Arial, sans-serif;
  font-size: 28px;
  color: ${colors.black};
  line-height: 42px;
`

export const body18Styles = `
  font-family: "Avenir Roman", Helvetica, Arial, sans-serif;
  font-size: 18px;
  color: ${colors.black};
  line-height: 27px;
`

export const body16BoldStyles = `
  font-family: "Avenir Heavy", Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: ${colors.black};
  line-height: 20px;
  font-weight: 800;
`

export const body16MainStyles = `
  font-family: "Avenir Roman", Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: ${colors.black};
  line-height: 20px;
`

export const body14Styles = `
  font-family: "Avenir Roman", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: ${colors.black};
  line-height: 18px;
`

export const body14BoldStyles = `
  font-family: "Avenir Heavy", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: ${colors.black};
  line-height: 18px;
`

export const paragraphStyles = `
  font-family: "Avenir Roman", Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: ${colors.black};
  line-height: 24px;
`

export const paragraph18Styles = `
  font-family: "Avenir Roman", Helvetica, Arial, sans-serif;
  font-size: 18px;
  color: ${colors.black};
  line-height: 32px;
`

export const paragraph20Styles = `
  font-family: "Avenir Roman", Helvetica, Arial, sans-serif;
  font-size: 20px;
  color: ${colors.black};
  line-height: 30px;
`

export const subtextStyles = `
  font-family: "Avenir Medium", Helvetica, Arial, sans-serif;
  font-size: 12px;
  color: ${colors.black};
  line-height: 16px;
`
