import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import GatsbyImage, { FluidObject, GatsbyImageProps } from "gatsby-image"

import colors from "@constants/colors"
import animation, { cubicBezier } from "@constants/animation"
import breakpoints from "@constants/breakpoints"
import { body16MainStyles } from "@constants/typography"
import AnimatedDash, { animationStyles } from "../buttons/common/AnimatedDash"
import { TabletAndDesktopOnly } from "@utils/MediaQuery"
import { Label } from "@components/Label"

const GatsbyLink = styled(Link)<any>`
  ${({ to }) => (!to.length ? "pointer-events: none" : "cursor: pointer")};
  ${animationStyles}
`

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const Content = styled.div`
  width: 100%;
  padding-top: 27px;
`

const Row = styled.div`
  display: flex;
  margin-bottom: 27px;
  align-items: center;
  justify-content: space-between;
`

const ImageWrapper = styled.div`
  transition: background-color ${animation.medium} ${cubicBezier};
  background-color: ${colors.backgroundGray};

  @media ${breakpoints.laptop} {
    &:hover {
      background-color: ${colors.backgroundGrayHover};
    }
  }
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 1:1 Aspect Ratio */
`

const Image = styled(GatsbyImage)<GatsbyImageProps>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
`

const Title = styled.div`
  ${body16MainStyles}
`

const DashWrapper = styled.div`
  margin-right: 16px;
`

export interface SearchBlockProps {
  slug: string
  name: string
  tag?: string
  fluid: FluidObject
  onClick?: (e: any) => void
}

const SearchBlock: React.FC<SearchBlockProps> = ({
  tag,
  slug,
  name,
  fluid,
  onClick,
}: SearchBlockProps) => {
  // getProductURL(slug)
  return (
    <GatsbyLink to={"/" + slug} onClick={onClick}>
      <Container>
        <ImageWrapper>
          <ImageContainer>
            <Image fluid={fluid} style={{ position: "absolute" }} />
          </ImageContainer>
        </ImageWrapper>
        <Content>
          {tag && (
            <Row>
              <Label text={tag} color={colors.offBlack} />
            </Row>
          )}
          <Row>
            <Title>{name}</Title>
            <TabletAndDesktopOnly>
              <DashWrapper>
                <AnimatedDash />
              </DashWrapper>
            </TabletAndDesktopOnly>
          </Row>
        </Content>
      </Container>
    </GatsbyLink>
  )
}

export default SearchBlock
